<template>
  <div>
    <v-row class="align-items-center">
      <v-col md="12" v-if="carsGridShow">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Araç Kataloğu</v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end" style="min-width: 750px">
              <input
                type="text"
                v-model="searchFilterInput"
                autocomplete="off"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />

              <RoleProvider slug="CAR_UPDATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="
                      warningModal = true;
                      checkFilterParams();
                    "
                    class="py-2 px-3 font-weight-bold mr-3"
                    v-if="canItPass"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Toplu Resim Güncelle
                  </CButton>
                </div>
              </RoleProvider>
              <RoleProvider slug="CAR_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="
                      cleanCarModalAddUpdateForm(),
                        (carModalAddUpdateOptions.process = 'post'),
                        (carModalAddUpdateOptions.title = 'Araç Ekle'),
                        (carModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                    v-if="canItPass"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Araç Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              :class="getClasses()"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :rowHeight="55"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
              :getContextMenuItems="getGridContextMenuItems"
            ></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <template>
      <div>
        <CModal
          title="Toplu Fotoğraf Yükleme"
          color="primary"
          :show.sync="warningModal"
        >
          <template>
            <div>
              <div class="container">
                <form>
                  <div class="form-group">
                    <input type="file" @change="onFileUpload($event)" />
                  </div>
                </form>
              </div>
            </div>
          </template>
          <template #footer>
            <CButton color="danger" @click="warningModal = false"
              >Vazgeç</CButton
            >
            <CButton
              color="success"
              :disabled="carModalAddUpdateBtnDisable"
              @click="bulkUploadOnSubmit"
              >Güncelle</CButton
            >
          </template>
        </CModal>
      </div>
    </template>
    <!-- :closeOnBackdrop="false" -->
    <CModal
      :title="carModalAddUpdateOptions.title"
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="carModalAddUpdate"
      class="carModalAddUpdateForm"
      id="modal-content"
    >
      <CContainer class="p-2">
        <CRow>
          <CCol col="5">
            <CContainer class="p-0">
              <CRow class="mb-3">
                <CCol col="12">
                  <div class="img-car-container">
                    <div class="img-car-upload-delete-container">
                      <div class="btn-icon-center">
                        <font-awesome-icon
                          id="btnResimYukle"
                          icon="cloud-upload-alt"
                          size="2x"
                          title="Resim Yükle"
                          @click="clickImageUpload()"
                        />
                        <font-awesome-icon
                          id="btnResimSil"
                          icon="trash-alt"
                          size="2x"
                          class="ml-4"
                          title="Resmi Sil"
                          v-if="
                            carModalAddUpdateForm.srcImage !==
                            ENV_URL_CARS + 'default_car.png'
                          "
                          @click="clickImageDelete()"
                        />
                      </div>
                      <input
                        id="inputCarImageFile"
                        type="file"
                        class="files"
                        ref="files"
                        custom
                        v-on:change="selectImage()"
                      />
                    </div>
                    <CImg :src="carModalAddUpdateForm.srcImage" fluid />
                  </div>
                </CCol>
              </CRow>
              <CRow class="mb-3">
                <CCol col="12">
                  <label>Marka</label>
                  <v-select
                    v-model="carModalAddUpdateForm.brand"
                    :options="carModalAddUpdateFormBrandOptions"
                    placeholder="Seçiniz"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CInput
                    label="Model"
                    v-model="carModalAddUpdateForm.model"
                    :value="carModalAddUpdateForm.model"
                  />
                </CCol>
              </CRow>
            </CContainer>
          </CCol>
          <CCol col="7">
            <CRow class="mb-3">
              <CCol col="6">
                <label for="vendor">Ürün Tipi</label>
                <div>
                  <v-select
                    v-model="carModalAddUpdateForm.productType"
                    :options="items.productTypes"
                    :reduce="(item) => item.slug"
                    placeholder="Seçiniz"
                    id="productTypes"
                  />
                </div>
              </CCol>
              <CCol col="6">
                <label>Sınıf</label>
                <v-select
                  v-model="carModalAddUpdateForm.class"
                  :options="carModalAddUpdateFormClassOptions"
                  :reduce="(item) => item.value"
                  placeholder="Seçiniz"
                  label="label"
                />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol col="6">
                <label>Ebat</label>
                <v-select
                  v-model="carModalAddUpdateForm.size"
                  :options="carModalAddUpdateFormSizeOptions"
                  :reduce="(item) => item.value"
                  placeholder="Seçiniz"
                  label="label"
                />
              </CCol>
              <CCol col="6">
                <label>Gövde</label>
                <v-select
                  v-model="carModalAddUpdateForm.body"
                  :options="carModalAddUpdateFormBodyOptions"
                  :reduce="(item) => item.value"
                  placeholder="Seçiniz"
                  label="label"
                />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol col="6">
                <label>Yakıt Tipi</label>
                <v-select
                  v-model="carModalAddUpdateForm.fuel"
                  :options="carModalAddUpdateFormFuelOptions"
                  :reduce="(item) => item.value"
                  placeholder="Seçiniz"
                  label="label"
                />
              </CCol>
              <CCol col="6">
                <label>Vites Tipi</label>
                <v-select
                  v-model="carModalAddUpdateForm.transmission"
                  :options="carModalAddUpdateFormTransmissionOptions"
                  :reduce="(item) => item.value"
                  placeholder="Seçiniz"
                  label="label"
                />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol col="6">
                <CInput
                  label="Koltuk Sayısı"
                  type="number"
                  step="1"
                  min="1"
                  max="22"
                  :class="{ shake: carModalAddUpdateOptions.shake.seat }"
                  v-model="carModalAddUpdateForm.seat"
                  :value="carModalAddUpdateForm.seat"
                  :invalid-feedback="carModalAddUpdateFormErrors.seat"
                  :is-valid="validSeat"
                />
              </CCol>
              <CCol col="6">
                <CInput
                  label="SIPP Kodu"
                  v-model="carModalAddUpdateForm.sipp"
                />
              </CCol>
              <CCol col="6">
                <label>Segment</label>
                <v-select
                  v-model="carModalAddUpdateForm.segment"
                  :options="carModalAddUpdateFormSegmentOptions"
                  :reduce="(item) => item.value"
                  placeholder="Seçiniz"
                  label="label"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <label for="status">Durum</label>
            <v-select
              v-model="carModalAddUpdateForm.status"
              :options="carModalAddUpdateFormStatusOptions"
              :reduce="(item) => item.value"
              placeholder="Seçiniz"
              label="label"
            />
          </CCol>
        </CRow>
      </CContainer>

      <template #footer>
        <RoleProvider slug="CAR_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="danger"
              v-if="carModalAddUpdateOptions.process === 'put' && canItPass"
              @click="carDelete(carModalAddUpdateForm._id)"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="CAR_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              :disabled="carModalAddUpdateBtnDisable"
              @click="carAddUpdate()"
              v-if="carModalAddUpdateOptions.process === 'post' && canItPass"
            >
              EKle
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="CAR_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="warning"
              :disabled="carModalAddUpdateBtnDisable"
              @click="carCopyOnEditing()"
              v-if="carModalAddUpdateOptions.process === 'put' && canItPass"
            >
              Çoğalt
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="CAR_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              :disabled="carModalAddUpdateBtnDisable"
              @click="carAddUpdate()"
              v-if="carModalAddUpdateOptions.process === 'put' && canItPass"
            >
              Düzenle
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="CAR_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="danger"
              v-if="carModalAddUpdateOptions.process === 'post' && canItPass"
              :disabled="carModalAddUpdateBtnDisable"
              @click="carAddUpdate(true)"
              >Çoğalt</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>
<style>
.modal-content {
  position: absolute;
  z-index: 9;
}
</style>
<script>
import Vue from "vue";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { RoleProvider } from "../../provider";

axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);
let gridApi;

export default {
  name: "CarSearch",
  data() {
    return {
      bulkUplodResponse: "",
      imageFile: null,
      ENV_URL_CARS: process.env.VUE_APP_CDN_URL_CARS,
      defaultCarImage: "default_car.png",
      //AG GRID
      carsGridShow: false,
      warningModal: false,
      columnDefs: [
        {
          field: "image",
          cellRenderer: (params) => {
            return (
              '<img src="' +
              this.ENV_URL_CARS +
              params.value +
              '" class="my-1" style="width: auto; height: 45px">'
            );
          },
          headerName: "Görsel",
          width: 130,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? {
                  color: "#2eb85c",
                  fontWeight: "bold",
                }
              : {
                  color: "#e55353",
                  fontWeight: "bold",
                };
          },
          headerName: "Durum",
          width: 110,
        },
        {
          field: "brand",
          headerName: "Marka",
          width: 100,
        },
        {
          field: "brandSlug",
          headerName: "Marka Slug",
          hide: true,
          width: 100,
        },
        {
          field: "model",
          headerName: "Model",
          width: 100,
        },
        {
          field: "modelSlug",
          headerName: "Model Slug",
          hide: true,
          width: 100,
        },
        {
          field: "fuel",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },
          headerName: "Yakıt",
          width: 80,
        },
        {
          field: "transmission",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },

          headerName: "Vites",
          width: 120,
        },
        {
          field: "productType",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },
          headerName: "Türü",
          width: 80,
        },
        {
          field: "class",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },

          headerName: "Sınıf",
          width: 80,
        },
        {
          field: "segment",
          headerName: "Segment",
          width: 100,
        },
        {
          field: "size",
          headerName: "Size",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },

          width: 80,
        },
        {
          field: "body",
          headerName: "Kasa",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },

          width: 100,
        },
        {
          field: "seat",
          headerName: "Koltuk",
          width: 80,
        },
        {
          field: "sipp",
          headerName: "SIPP Code",
          width: 120,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması..
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      // ARAÇ ARAMA
      searchCarFormCollapsed: false,
      searchCarFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchCarFormBrandOptions: [
        {
          value: "",
          label: "Tümü",
        },
        "Alfa Romeo",
        "Aston Martin",
        "Astra",
        "Audi",
        "Bentley",
        "Bmc",
        "Jeep",
        "Ds Automobiles",
        "Bmw",
        "Bugatti",
        "Cadillac",
        "Chery",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Cupra",
        "Dacia",
        "Daihatsu",
        "Ferrari",
        "Fiat",
        "Ford",
        "Geely",
        "Gmc",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Karsan",
        "Kia",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "Mclaren",
        "Mercedes",
        "Mercury",
        "Mg",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Proton",
        "Range Rover",
        "Renault",
        "Rolls-Royce",
        "Saab",
        "Seat",
        "Skoda",
        "Skywell",
        "Smart",
        "Ssangyong",
        "Subaru",
        "Suzuki",
        "Tata",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      searchCarForm: {
        status: "",
        brand: "",
        model: "",
      },
      // ARAÇ EKLEME GÜNCELLEME MODAL
      carModalAddUpdate: false, // Car Modal Add Update
      carModalAddUpdateBtnDisable: false, // Car Modal Add Update button disabled enabled değişkeni
      carModalAddUpdateOptions: {
        // Car Modal Add Update değişkenleri
        process: "",
        title: "",
        shake: {
          model: false,
          seat: false,
        },
      },
      carModalAddUpdateFormBrandOptions: [
        "Alfa Romeo",
        "Aston Martin",
        "Astra",
        "Audi",
        "Bentley",
        "Bmc",
        "Bmw",
        "Jeep",
        "Ds Automobiles",
        "Bugatti",
        "Cadillac",
        "Chery",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Cupra",
        "Dacia",
        "Daihatsu",
        "Ferrari",
        "Fiat",
        "Ford",
        "Geely",
        "Gmc",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Karsan",
        "Kia",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "Mclaren",
        "Mercedes",
        "Mercury",
        "Mg",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Proton",
        "Range Rover",
        "Renault",
        "Rolls-Royce",
        "Saab",
        "Seat",
        "Skoda",
        "Skywell",
        "Smart",
        "Ssangyong",
        "Subaru",
        "Suzuki",
        "Tata",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      carModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      carModalAddUpdateFormClassOptions: [
        {
          value: "economic",
          label: "Ekonomik",
        },
        {
          value: "luxury",
          label: "Lüks",
        },
        {
          value: "medium",
          label: "Standart",
        },
        {
          value: "commercial",
          label: "Ticari",
        },
      ],
      carModalAddUpdateFormSegmentOptions: [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        },
        {
          value: "E",
          label: "E",
        },
        {
          value: "F",
          label: "F",
        },
        {
          value: "G",
          label: "G",
        },
        {
          value: "M",
          label: "M",
        },
        {
          value: "J",
          label: "J",
        },
      ],
      carModalAddUpdateFormSizeOptions: [
        {
          value: "small",
          label: "Küçük",
        },
        {
          value: "medium",
          label: "Orta",
        },
        {
          value: "large",
          label: "Büyük",
        },
        {
          value: "stationWagon",
          label: "Station Wagon",
        },
        {
          value: "premium",
          label: "Premium",
        },
        {
          value: "minivan",
          label: "Minivan",
        },
        {
          value: "Monospace",
          label: "monospace",
        },
        {
          value: "suv",
          label: "Suv",
        },
      ],
      carModalAddUpdateFormBodyOptions: [
        {
          value: "sedan",
          label: "Sedan",
        },
        {
          value: "hatchback",
          label: "Hatchback",
        },
        {
          value: "crossover",
          label: "Crossover",
        },
        {
          value: "suv",
          label: "Suv",
        },
        {
          value: "wagon",
          label: "Wagon",
        },
        {
          value: "coupe",
          label: "Coupe",
        },
        {
          value: "pickup",
          label: "Pickup",
        },
        {
          value: "compact",
          label: "Compact",
        },
        {
          value: "cabriolet",
          label: "Cabriolet",
        },
        {
          value: "van",
          label: "Van",
        },
      ],
      carModalAddUpdateFormTransmissionOptions: [
        {
          value: "manuel",
          label: "Manuel",
        },
        {
          value: "automatic",
          label: "Otomatik",
        },
      ],
      carModalAddUpdateFormFuelOptions: [
        {
          value: "gas",
          label: "Benzin",
        },
        {
          value: "diesel",
          label: "Dizel",
        },
        {
          value: "lpg",
          label: "LPG",
        },
        {
          value: "hybrit",
          label: "Hibrit",
        },
        {
          value: "electric",
          label: "Elektrikli",
        },
        {
          value: "unspecified",
          label: "Belirtilmemiş",
        },
      ],
      carModalAddUpdateFormErrors: {
        model: "",
        seat: "",
      },
      carModalAddUpdateForm: {
        _id: "",
        srcImage: "",
        files: null,
        brand: "",
        brandSlug: "",
        model: "",
        modelSlug: "",
        status: false,
        class: "",
        segment: "",
        sipp: "",
        size: "",
        body: "",
        seat: 1,
        transmission: "",
        productType: "",
        fuel: "",
      },
      items: {
        productTypes: [],
      },
      selectedFilter: {
        brand: new Array(),
        model: new Array(),
        body: new Array(),
      },
      searchFilterInput: "",
    };
  },
  components: {
    AgGridVue,
    vSelect,
    RoleProvider,
  },
  watch: {
    carModalAddUpdate: function (val) {
      if (val) {
        this.itemsProductType();
      }
    },

    "carModalAddUpdateForm.brand": function () {
      if (this.carModalAddUpdateForm.brand != "")
        this.carModalAddUpdateForm.brandSlug = Func.slugify(
          this.carModalAddUpdateForm.brand
        );
      else this.carModalAddUpdateForm.brandSlug = "";
    },
    "carModalAddUpdateForm.model": function (val) {
      if (this.carModalAddUpdateForm.model != "")
        this.carModalAddUpdateForm.modelSlug = Func.slugify(
          this.carModalAddUpdateForm.model
        );
      else this.carModalAddUpdateForm.modelSlug = "";
    },
  },

  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFileUpload(event) {
      this.imageFile = event.target.files[0];
    },
    bulkUploadOnSubmit() {
      if (this.selectedFilter.brand.length > 1) {
        Vue.swal({
          icon: "error",
          title: "Toplu Fotoğraf Güncelleme",
          html: "Toplu fotoğraf ekleme İşleminde birden fazla marka seçemezsiniz",
          confirmButtonText: "Tamam",
        });
      } else if (this.selectedFilter.model.length > 1) {
        Vue.swal({
          icon: "error",
          title: "Toplu Fotoğraf Güncelleme",
          html: "Toplu fotoğraf ekleme İşleminde birden fazla model seçemezsiniz",
          confirmButtonText: "Tamam",
        });
      } else if (this.selectedFilter.body.length > 1) {
        Vue.swal({
          icon: "error",
          title: "Toplu Fotoğraf Güncelleme",
          html: "Toplu fotoğraf ekleme İşleminde birden fazla kasa seçemezsiniz",
          confirmButtonText: "Tamam",
        });
      } else {
        const formData = new FormData();
        formData.append("image", this.imageFile, this.imageFile.name);
        formData.append("body", this.selectedFilter.body[0]);
        formData.append("model", this.selectedFilter.model[0]);
        formData.append("brand", this.selectedFilter.brand[0]);
        axios
          .post(
            process.env.VUE_APP_API_URL + "admin/cars/bulkimageupdate",
            formData,
            {}
          )
          .then((res) => {
            this.bulkUplodResponse = res.data.message;
            Vue.swal({
              icon: res.data.result,
              title: "Toplu Resim Güncelleme",
              html: res.data.message,
              confirmButtonText: "Tamam",
            });
            if (res.data.result === "success") {
              this.warningModal = false;
              this.searchCar();
            }
          });
      }
    },
    getClasses() {
      let classes = {
        "ag-theme-balham": true,
      };
      if (!this.searchCarFormCollapsed) {
        classes["fp-table"] = true;
      } else {
        classes["fp-table-open"] = true;
      }
      return classes;
    },

    getGridContextMenuItems(params) {
      var _this = this;
      var selectedRowCount = params.api.getSelectedRows().length;
      var result = [
        {
          name: "Toplu Sil (" + selectedRowCount + " Araç)",
          disabled: selectedRowCount === 0 ? true : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            Vue.swal
              .fire({
                title: "Uyarı ?",
                text:
                  "Toplam " +
                  selectedRowCount +
                  " aracı silmek istediğinize emin misiniz?",
                icon: "question",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  _this.$Progress.start();
                  try {
                    for (const item of selectedRows) {
                      axios.delete(
                        process.env.VUE_APP_API_URL + "admin/cars/" + item._id,
                        {
                          withCredentials: true,
                        }
                      );
                    }
                    setTimeout(() => {
                      if (_this.carsGridShow) _this.searchCar();
                    }, 3000);
                    _this.$Progress.finish();

                    _this.$store.dispatch({
                      type: "successSwal",
                      text: "Araçlar başarılı bir şekilde silindi. Ekranın yenilenmesini bekleyin.",
                      refresh: false,
                    });
                  } catch (error) {
                    Vue.swal.fire({
                      icon: "error",
                      showCloseButton: true,
                      text: "Araçlar silinirken hata oluştu.",
                    });
                  }
                }
              });
          },
          cssClasses: ["redFont", "bold"],
        },
        {
          name: "Toplu SIPP Kodu Güncelle (" + selectedRowCount + " Araç)",
          disabled: selectedRowCount === 0 ? true : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            Vue.swal
              .fire({
                title: "Uyarı ?",
                text:
                  "Toplam " +
                  selectedRowCount +
                  " aracın SIPP kodu güncellenecek?",
                icon: "question",
                input: "text",
                inputPlaceholder: "SIPP Kodu",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: "Güncelle",
                cancelButtonText: "Vazgeç",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  _this.$Progress.start();
                  try {
                    for (const item of selectedRows) {
                      axios.put(
                        process.env.VUE_APP_API_URL +
                          "admin/cars/sipp/" +
                          item._id,
                        { sipp: result.value },
                        {
                          withCredentials: true,
                        }
                      );
                    }
                    setTimeout(() => {
                      if (_this.carsGridShow) _this.searchCar();
                    }, 3000);
                    _this.$Progress.finish();

                    _this.$store.dispatch({
                      type: "successSwal",
                      text: "Araçlar başarılı bir şekilde güncellendi. Ekranın yenilenmesini bekleyin.",
                      refresh: false,
                    });
                  } catch (error) {
                    Vue.swal.fire({
                      icon: "error",
                      showCloseButton: true,
                      text: "Araçlar güncellenirken hata oluştu.",
                    });
                  }
                }
              });
          },
          cssClasses: ["redFont", "bold"],
        },
        "separator",
        {
          name: "Aracı Düzenle",
          action: function () {
            _this.cleanCarModalAddUpdateForm();

            _this.carModalAddUpdateOptions = {
              process: "put",
              title: "Aracı Güncelle",
            };

            const {
              _id,
              brand,
              brandSlug,
              model,
              modelSlug,
              segment,
              size,
              body,
              seat,
              productType,
              transmission,
              fuel,
              status,
            } = params.node.data;

            _this.carModalAddUpdateForm = {
              _id,
              brand,
              brandSlug,
              model,
              modelSlug,
              segment,
              size,
              body,
              seat,
              productType,
              transmission,
              fuel,
              status,
            };

            _this.carModalAddUpdateForm.class = params.node.data.class;
            _this.carModalAddUpdateForm.sipp = params.node.data.sipp
              ? params.node.data.sipp.toUpperCase()
              : params.node.data.sipp;

            _this.carModalAddUpdateForm.srcImage =
              _this.ENV_URL_CARS + params.node.data.image;
            _this.carModalAddUpdate = true;
          },
        },
        {
          name: "Kopyala",
          action: function () {
            _this.gridApi.copySelectedRangeToClipboard();
          },
        },
        "separator",
        "chartRange",
        "copy",
        "export",
      ];
      return result;
    },
    validModel(val) {
      if (val) {
        if (val.toString().length > 30) {
          this.carModalAddUpdateFormErrors.model =
            "Bu alana en fazla 30 karakter girilebilir!";
          return false;
        } else {
          this.carModalAddUpdateFormErrors.model = "";
          return true;
        }
      } else {
        this.carModalAddUpdateFormErrors.model = "";
        // "Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validSeat(val) {
      if (val) {
        if (val > 22) {
          this.carModalAddUpdateFormErrors.seat =
            "Koltuk sayısı en fazla 22 olabilir!";
          return false;
        } else if (!val.toString().match(/^[0-9]+$/g)) {
          this.carModalAddUpdateFormErrors.seat =
            "Bu alana yalnızca sayı değeri girilebilir!";
          return false;
        } else {
          this.carModalAddUpdateFormErrors.seat = "";
          return true;
        }
      } else {
        this.carModalAddUpdateFormErrors.seat = "";
        // "Bu alan boş bırakılamaz!";
        return false;
      }
    },
    checkFilterParams() {
      Object.keys(this.selectedFilter).forEach((objectKey) => {
        this.selectedFilter[`${objectKey}`] = [];
        if (
          this.gridApi.getFilterInstance(`${objectKey}`).valueModel
            .availableValues.size === 1
        ) {
          this.gridApi
            .getFilterInstance(`${objectKey}`)
            .valueModel.availableValues.forEach((item) => {
              this.selectedFilter[`${objectKey}`].push(item);
            });
        } else {
          this.gridApi
            .getFilterInstance(`${objectKey}`)
            .valueModel.selectedValues.forEach((item) => {
              this.selectedFilter[`${objectKey}`].push(item);
            });
        }
      });
    },
    cleanCarModalAddUpdateForm() {
      this.carModalAddUpdateForm._id = "";
      this.carModalAddUpdateForm.srcImage =
        this.ENV_URL_CARS + this.defaultCarImage;
      this.carModalAddUpdateForm.files = null;
      this.carModalAddUpdateForm.brand =
        this.carModalAddUpdateFormBrandOptions[0];
      this.carModalAddUpdateForm.brandSlug = Func.slugify(
        this.carModalAddUpdateFormBrandOptions[0]
      );
      this.carModalAddUpdateForm.model = "";
      this.carModalAddUpdateForm.modelSlug = "";
      this.carModalAddUpdateForm.sipp = "";
      this.carModalAddUpdateForm.status =
        this.carModalAddUpdateFormStatusOptions[0].value;
      this.carModalAddUpdateForm.class =
        this.carModalAddUpdateFormClassOptions[0].value;
      this.carModalAddUpdateForm.segment =
        this.carModalAddUpdateFormSegmentOptions[0].value;
      this.carModalAddUpdateForm.size =
        this.carModalAddUpdateFormSizeOptions[0].value;
      this.carModalAddUpdateForm.body =
        this.carModalAddUpdateFormBodyOptions[0].value;
      this.carModalAddUpdateForm.seat = 1;
      this.carModalAddUpdateForm.productType = "car";

      this.carModalAddUpdateForm.transmission =
        this.carModalAddUpdateFormTransmissionOptions[0].value;
      this.carModalAddUpdateForm.fuel =
        this.carModalAddUpdateFormFuelOptions[0].value;

      document.getElementById("inputCarImageFile").value = "";
    },
    clickImageUpload() {
      document.getElementById("inputCarImageFile").click();
    },
    clickImageDelete() {
      this.carModalAddUpdateForm.srcImage =
        this.ENV_URL_CARS + this.defaultCarImage;
      this.carModalAddUpdateForm.files = null;
      document.getElementById("inputCarImageFile").value = "";
    },
    selectImage() {
      const _this = this;
      const fileImage = this.$refs.files.files[0];

      if (fileImage.size > 1048576) {
        Vue.swal({
          icon: "error",
          title: "Araç Ekle - Resim",
          html: "Maksimum 1 MB resim yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      } else {
        if (
          fileImage.type === "image/png" ||
          fileImage.type === "image/jpg" ||
          fileImage.type === "image/jpeg"
        ) {
          let reader = new FileReader();

          reader.onload = function (e) {
            _this.carModalAddUpdateForm.srcImage = e.target.result;
            _this.carModalAddUpdateForm.files = fileImage;
          };
          reader.readAsDataURL(fileImage);
        } else {
          Vue.swal({
            icon: "error",
            title: "Araç Ekle - Resim",
            html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        }
      }
    },
    searchCar() {
      this.$Progress.start();
      axios
        .get(process.env.VUE_APP_API_URL + "admin/cars", {
          params: this.searchCarForm,
        })
        .then((response) => {
          this.rowData = response.data.carList;
          this.searchCarFormCollapsed = false;
          this.carsGridShow = true;
          this.$Progress.finish();
        });
    },
    carAddUpdate(cogalt) {
      // Modal Form içinde eğer model alanında hata var ise shake yapıyoruz.
      if (this.carModalAddUpdateFormErrors.model !== "") {
        this.carModalAddUpdateOptions.shake.model = true;

        setTimeout(() => {
          this.carModalAddUpdateOptions.shake.model = false;
        }, 1000);
      }

      // Modal Form içinde eğer seat alanında hata var ise shake yapıyoruz.
      if (this.carModalAddUpdateFormErrors.seat !== "") {
        this.carModalAddUpdateOptions.shake.seat = true;

        setTimeout(() => {
          this.carModalAddUpdateOptions.shake.seat = false;
        }, 1000);
      }

      // Modal Form içinde eğer hata var ise return ile işlemi durduruyoruz.
      if (
        this.carModalAddUpdateFormErrors.model !== "" ||
        this.carModalAddUpdateFormErrors.seat !== ""
      )
        return;

      this.$Progress.start();
      this.carModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      var formData = new FormData();

      if (this.carModalAddUpdateOptions.process === "put")
        formData.append("_id", this.carModalAddUpdateForm._id);

      if (
        this.carModalAddUpdateForm.files !== null &&
        this.carModalAddUpdateForm.files.size > 0
      )
        formData.append("image", this.carModalAddUpdateForm.files);
      else if (
        this.carModalAddUpdateForm.srcImage ===
        this.ENV_URL_CARS + this.defaultCarImage
      ) {
        formData.append("image", this.defaultCarImage);
      }

      const formDataItems = [
        { key: "brand", value: this.carModalAddUpdateForm.brand },
        { key: "brandSlug", value: this.carModalAddUpdateForm.brandSlug },
        { key: "model", value: this.carModalAddUpdateForm.model },
        { key: "modelSlug", value: this.carModalAddUpdateForm.modelSlug },
        { key: "status", value: this.carModalAddUpdateForm.status },
        { key: "class", value: this.carModalAddUpdateForm.class },
        { key: "segment", value: this.carModalAddUpdateForm.segment },
        { key: "size", value: this.carModalAddUpdateForm.size },
        { key: "body", value: this.carModalAddUpdateForm.body },
        { key: "seat", value: this.carModalAddUpdateForm.seat },
        {
          key: "sipp",
          value: this.carModalAddUpdateForm.sipp
            ? this.carModalAddUpdateForm.sipp.toUpperCase()
            : this.carModalAddUpdateForm.sipp,
        },
        { key: "productType", value: this.carModalAddUpdateForm.productType },
        {
          key: "transmission",
          value: this.carModalAddUpdateForm.transmission,
        },
        { key: "fuel", value: this.carModalAddUpdateForm.fuel },
      ];

      formDataItems.forEach((item) => {
        formData.append(item.key, item.value);
      });

      axios[this.carModalAddUpdateOptions.process](
        process.env.VUE_APP_API_URL + "admin/cars",
        formData,
        {
          headers: {
            "Content-Type": false,
          },
        }
      ).then((response) => {
        this.$Progress.finish();
        this.carModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

        if (response.data.result === "success") {
          if (this.carsGridShow) this.searchCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap

          //SORU(DOGU)=> bu "cogalt" adındaki param ve button tam olarak ne yapıyor ?

          if (cogalt) {
          } else {
            this.carModalAddUpdate = false; // Modal'ı kapattık
          }
        }

        Vue.swal({
          icon: response.data.result,
          title:
            "Araç " + this.carModalAddUpdateOptions.process === "post"
              ? "Ekle"
              : "Düzenle",
          html: response.data.message,
          confirmButtonText: "Tamam",
        });
      });
    },

    carCopyOnEditing() {
      // Araç düzenleme ekranındaki çoğalt butonu

      if (this.carModalAddUpdateFormErrors.model !== "") {
        this.carModalAddUpdateOptions.shake.model = true;

        setTimeout(() => {
          this.carModalAddUpdateOptions.shake.model = false;
        }, 1000);
      }

      // Modal Form içinde eğer seat alanında hata var ise shake yapıyoruz.
      if (this.carModalAddUpdateFormErrors.seat !== "") {
        this.carModalAddUpdateOptions.shake.seat = true;

        setTimeout(() => {
          this.carModalAddUpdateOptions.shake.seat = false;
        }, 1000);
      }

      // Modal Form içinde eğer hata var ise return ile işlemi durduruyoruz.
      if (
        this.carModalAddUpdateFormErrors.model !== "" ||
        this.carModalAddUpdateFormErrors.seat !== ""
      )
        return;

      this.$Progress.start();
      this.carModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      var formData = new FormData();

      if (
        this.carModalAddUpdateForm.files !== null &&
        this.carModalAddUpdateForm.files.size > 0
      )
        formData.append("image", this.carModalAddUpdateForm.files);
      else if (
        this.carModalAddUpdateForm.srcImage ===
        this.ENV_URL_CARS + this.defaultCarImage
      )
        formData.append("image", this.defaultCarImage);

      formData.append("brand", this.carModalAddUpdateForm.brand);
      formData.append("brandSlug", this.carModalAddUpdateForm.brandSlug);
      formData.append("model", this.carModalAddUpdateForm.model);
      formData.append("modelSlug", this.carModalAddUpdateForm.modelSlug);
      formData.append("status", this.carModalAddUpdateForm.status);
      formData.append("class", this.carModalAddUpdateForm.class);
      formData.append("segment", this.carModalAddUpdateForm.segment);
      formData.append(
        "sipp",
        this.carModalAddUpdateForm.sipp
          ? this.carModalAddUpdateForm.sipp.toUpperCase()
          : this.carModalAddUpdateForm.sipp
      );
      formData.append("size", this.carModalAddUpdateForm.size);
      formData.append("body", this.carModalAddUpdateForm.body);
      formData.append("seat", this.carModalAddUpdateForm.seat);

      formData.append("productType", this.carModalAddUpdateForm.productType);
      formData.append("transmission", this.carModalAddUpdateForm.transmission);
      formData.append("fuel", this.carModalAddUpdateForm.fuel);

      axios
        .post(process.env.VUE_APP_API_URL + "admin/cars", formData, {
          headers: {
            "Content-Type": false,
          },
        })
        .then((response) => {
          this.$Progress.finish();
          this.carModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

          if (response.data.result === "success") {
            if (this.carsGridShow) this.searchCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
          }

          Vue.swal({
            icon: response.data.result,
            title: "Araç Çoğaltma",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    carDelete(id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu aracı silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(process.env.VUE_APP_API_URL + "admin/cars/" + id, {
                withCredentials: true,
              });
              this.$Progress.finish();

              this.$store.dispatch({
                type: "successSwal",
                text: "Araç başarılı bir şekilde silindi",
                refresh: true,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanCarModalAddUpdateForm();
      _this.carModalAddUpdateOptions.process = "put";
      _this.carModalAddUpdateOptions.title = "Aracı Güncelle";
      _this.carModalAddUpdateForm._id = params.node.data._id;
      _this.carModalAddUpdateForm.brand = params.node.data.brand;
      _this.carModalAddUpdateForm.brandSlug = params.node.data.brandSlug;
      _this.carModalAddUpdateForm.model = params.node.data.model;
      _this.carModalAddUpdateForm.modelSlug = params.node.data.modelSlug;
      _this.carModalAddUpdateForm.class = params.node.data.class;
      _this.carModalAddUpdateForm.segment = params.node.data.segment;
      _this.carModalAddUpdateForm.sipp = params.node.data.sipp
        ? params.node.data.sipp.toUpperCase()
        : params.node.data.sipp;
      _this.carModalAddUpdateForm.size = params.node.data.size;
      _this.carModalAddUpdateForm.body = params.node.data.body;
      _this.carModalAddUpdateForm.seat = params.node.data.seat;
      _this.carModalAddUpdateForm.productType = params.node.data.productType;

      _this.carModalAddUpdateForm.transmission = params.node.data.transmission;
      _this.carModalAddUpdateForm.fuel = params.node.data.fuel;
      _this.carModalAddUpdateForm.srcImage =
        _this.ENV_URL_CARS + params.node.data.image;
      _this.carModalAddUpdateForm.status = params.node.data.status;
      _this.carModalAddUpdate = true;
    },
    async itemsProductType() {
      try {
        let productTypes = await axios.get(
          process.env.VUE_APP_API_URL + "product-type",
          {
            withCredentials: true,
          }
        );
        this.items.productTypes = productTypes.data.data;
        this.items.productTypes.map(function (x) {
          return (x.label = x.name.TR);
        });
      } catch (error) {}
    },
  },
  created: function () {
    this.searchCar();
  },
};
</script>
